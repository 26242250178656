



















































































import { post, t } from "#root/lib/utils";
import Vue from "vue";
import { WorldCreation, WorldInfo } from "#mws/interfaces";
import { Endpoints, LocaleKeys, Rules } from "#mws/data";

import * as THREE from "three";

const renderer = new THREE.WebGLRenderer({ alpha: true });
renderer.setClearColor(0, 0);

const camera = new THREE.OrthographicCamera(0, 0, 0, 0, 1, 512);
camera.position.set(-25.7, 48, 72);
camera.lookAt(0, 0, -32);

const geometry = new THREE.BoxGeometry(1, 1, 1);
import blockTexture from "#root/textures/block.png";
import SessionModule from "#root/store/session";
const texture = new THREE.TextureLoader().load(blockTexture);
texture.wrapS = THREE.RepeatWrapping;
texture.wrapT = THREE.RepeatWrapping;
const material = new THREE.MeshLambertMaterial({
  map: texture,
  side: THREE.DoubleSide,
  transparent: true,
  depthWrite: false,
});
const cube = new THREE.Mesh(geometry, material);
cube.position.set(0, 0, 0);

const light = new THREE.DirectionalLight(0xffffff, 1.0);
light.position.set(-1, 2, 1.5);

const scene = new THREE.Scene();
scene.add(cube);
scene.add(light);

export default Vue.extend({
  name: "WorldCreation",
  data: () => ({
    valid: false,
    name: "",
    sx: 72,
    sy: 48,
    sz: 72,
    minDimension: Rules.WORLD.MIN_DIMENSION_SIZE,
    maxDimension: Rules.WORLD.MAX_DIMENSION_SIZE,
    lk: LocaleKeys,
    step: 8,
  }),
  methods: {
    t: t,
    updateRender() {
      cube.scale.x = this.sx;
      //cube.position.x = this.sx / 2.0;

      cube.scale.y = this.sy;
      //cube.position.y = this.sy / 2.0;

      cube.scale.z = this.sz;
      cube.position.z = -this.sz / 2.0;

      var uvAttribute = geometry.attributes.uv;

      let i = 0;
      for (let j = 0; j < 8; j++, i++) {
        uvAttribute.setXY(
          i,
          uvAttribute.getX(i) > 0 ? cube.scale.z / 8.0 : 0,
          uvAttribute.getY(i) > 0 ? cube.scale.y / 8.0 : 0
        );
      }
      for (let j = 0; j < 8; j++, i++) {
        uvAttribute.setXY(
          i,
          uvAttribute.getX(i) > 0 ? cube.scale.x / 8.0 : 0,
          uvAttribute.getY(i) > 0 ? cube.scale.z / 8.0 : 0
        );
      }
      for (let j = 0; j < 8; j++, i++) {
        uvAttribute.setXY(
          i,
          uvAttribute.getX(i) > 0 ? cube.scale.x / 8.0 : 0,
          uvAttribute.getY(i) > 0 ? cube.scale.y / 8.0 : 0
        );
      }
      uvAttribute.needsUpdate = true;

      renderer.render(scene, camera);
    },
    createWorld(): void {
      post<WorldCreation, WorldInfo>({
        endpoint: Endpoints.HTTP.worlds.create,
        auth: true,
        data: {
          name: this.name,
          size: [this.sx, this.sy, this.sz],
        },
      }).then((result) => {
        this.$emit("setShown", false);
        SessionModule.addUserWorld(result);
      });
    },
  },
  computed: {
    sizeExceeded(): boolean {
      return this.sx * this.sy * this.sz > Rules.WORLD.MAX_SIZE;
    },
    nameRule() {
      return [
        (value: string) =>
          value.length >= Rules.WORLD_NAME.MIN_CHAR ||
          t(LocaleKeys.fieldError.minCharacters, [Rules.WORLD_NAME.MIN_CHAR]),
        (value: string) =>
          value.length <= Rules.WORLD_NAME.MAX_CHAR ||
          t(LocaleKeys.fieldError.maxCharacters, [Rules.WORLD_NAME.MAX_CHAR]),
        (value: string) =>
          Rules.WORLD_NAME.REGEX.test(value) ||
          t(LocaleKeys.fieldError.usernameInvalid),
      ];
    },
    shownProp: {
      get() {
        return this.shown;
      },
      set(value) {
        this.$emit("setShown", value);
      },
    },
  },
  watch: {
    shown(value) {
      if (value) {
        this.sx = 72;
        this.sy = 48;
        this.sz = 72;

        this.$nextTick().then(() => {
          let container = document.getElementById("threeJSContainer");
          if (container !== null) {
            renderer.setSize(container.clientWidth, container.clientHeight);
            let height = 162.0;
            let width =
              (container.clientWidth * height) / container.clientHeight;
            camera.left = -width / 2.0;
            camera.right = width / 2.0;
            camera.top = height / 2.0;
            camera.bottom = -height / 2.0;
            camera.updateProjectionMatrix();
            container.appendChild(renderer.domElement);
            this.updateRender();
          }
        });
      }
    },
  },
  props: {
    shown: {
      type: Boolean,
      default: false,
    },
  },
});
